import React from 'react'
import gfm from 'remark-gfm'
import { graphql, useStaticQuery } from 'gatsby'
import { Typography, Divider } from '@mui/material'

import { SubContainer, CustomMarkdown } from '../components/common/Styled'
import { buildImageLink } from '../helpers'
import SEO from '../components/common/seo'

const query = graphql`
  query {
    personalData: strapiPersonalData {
      text {
        data {
          text
        }
      }
    }
  }
`

const title = 'Politique de confidentialité'

const PrivacyPolicyPage = () => {
  const { personalData } = useStaticQuery(query)

  return (
    <SubContainer>
      <SEO {...{ title }} />
      <Typography
        variant="h2"
        data-sal="slide-right"
        data-sal-delay="300"
        data-sal-easing="ease"
      >
        {title}
      </Typography>
      <Divider />
      <Typography variant="body1" component="div">
        <CustomMarkdown
          remarkPlugins={[gfm]}
          transformImageUri={buildImageLink}
        >
          {personalData?.text?.data?.text}
        </CustomMarkdown>
      </Typography>
    </SubContainer>
  )
}
export default PrivacyPolicyPage
